@charset "utf-8";
@import "../_partial/commonFunctions";
@import "../_partial/button";
@import "../_partial/header";
@import "../_partial/footer";

@include font-face('Material Icons', 'MaterialIcons-Regular');
@include font-face('opensans-bold', 'opensans-bold-webfont');
@include font-face('opensans-light', 'opensans-light-webfont');
@include font-face('opensans-regular', 'opensans-regular-webfont');
@include font-face('opensans-semibold', 'opensans-semibold-webfont');
@include font-face('pridi-bold', 'pridi-bold-webfont');
@include font-face('pridi-light', 'pridi-light-webfont');
@include font-face('pridi-regular', 'pridi-regular-webfont');
@include font-face('pridi-semibold', 'pridi-semibold-webfont');

* {
	box-sizing: border-box;
	font-family: arial;
	list-style: none;
	margin: 0;
	padding: 0;
	text-decoration: none;
}

img {
	display: block;
}

a {
	display: inline-block;

	&:active,
	&:focus,
	&:hover,
	&:visited {
		text-decoration: none;
	}
}

.container {
	margin: 0 auto;
	padding: 0 15px;

	@include media-query(sm) {
		width: 750px;
	}

	@include media-query(md) {
		width: 970px;
	}

	@include media-query(lg) {
		width: 1170px;
	}
}

.container--inside {
	margin: 0 auto;
	padding: 0 15px;

	@include media-query(lg) {
		width: 815px;
	}
}

.material-icons {
	color: white;
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}