@charset "utf-8";
@import "commonFunctions";
@import "globalVariables";

.Button {
	align-items: center;
	background: white;
	border: 1px solid $lynch;
	color: $lynch;
	cursor: pointer;
	display: inline-flex;
	font-family: opensans-regular, $fontOptions;
	font-size: 14px;
	padding: 14px 16px;
	text-transform: uppercase;
	transition: color .3s ease-in-out, background .3s ease-in-out;

	&:hover {
		@include media-query(md) {
			background: $lynch !important;
			color: white;

			i {
				color: white;
			}
		}
	}

	&.Button--inverted {
		background: $lynch;
		color: white;

		&:hover {
			@include media-query(md) {
				color: $lynch;
				background: transparent !important;
			}
		}
	}

	i {
		color: $lynch;
		transition: color .3s ease-in-out;
	}
}