@charset "UTF-8";

@mixin font-face($font-family, $font-filename, $font-weight : normal, $font-style :normal, $font-stretch : normal) {
	$path: '../font/';
	@font-face {
		font-family: '#{$font-family}';
		src: url($path + '#{$font-filename}.woff2') format('woff2'), url($path + '#{$font-filename}.woff') format('woff');
		font-weight: $font-weight;
		font-style: $font-style;
		font-stretch: $font-stretch;
	}
}

@mixin media-query($point) {
	@if $point == xlg {
		@media (min-width: 1600px) {
			@content;
		}
	} @else if $point == lg {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point == md {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point == sm {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point == xs {
		@media (max-width: 767px) {
			@content;
		}
	}
}


/**
 Clearfix
 For modern browsers
 1. The space content is one way to avoid an Opera bug when the
    contenteditable attribute is included anywhere else in the document.
    Otherwise it causes space to appear at the top and bottom of elements
    that are clearfixed.
 2. The use of `table` rather than `block` is only necessary if using
    `:before` to contain the top-margins of child elements.

 Source: http://nicolasgallagher.com/micro-clearfix-hack/
 */
@mixin clearfix() {
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
}