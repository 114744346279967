@charset "utf-8";
@import "commonFunctions";
@import "globalVariables";

.Footer {
	background-color: $lynch;
	padding: 30px 0 15px 0;
}

.Footer-columns {
	align-items: stretch;
	display: flex;
	justify-content: center;
}

.Footer-column {
	&:nth-of-type(1) {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		margin-right: 100px;
	}
}

.Footer-column--title {
	color: white;
	font-family: opensans-regular, $fontOptions;
	font-size: 10px;
}

.Footer-column--links {
	align-items: center;
	display: flex;
	height: 85px;

	a {
		&:nth-of-type(2) {
			margin-left: 36px;
		}
	}
}