@charset "utf-8";
@import "commonFunctions";
@import "globalVariables";
@import "menu";

.Header {
	position: relative;
	z-index: 5;
}

.Header--internal {
	background: url(../image/global/background.jpg) center center no-repeat;
	background-size: cover;
	position: relative;

	&:before {
		background: rgba(0, 0, 0, 0.4);
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.Header-content {
		justify-content: space-between;
		padding: 15px 0;

		.Header-logo {
			z-index: 5;
		}

		.Menu {
			border-bottom: none;
			margin: 0;
			width: 700px;
			z-index: 5;

			@include media-query(lg) {
				width: 850px;
			}
		}

		.Menu-item--link {
			font-size: 16px;
			padding: 0;

			@include media-query(lg) {
				font-size: 18px;
			}
		}
	}
}

.Header-content {
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
}