@charset "utf-8";
@import "commonFunctions";
@import "globalVariables";

.Menu {
	border-bottom: 1px solid white;
	display: flex;
	justify-content: space-between;
	width: 800px;

	@include media-query(lg) {
		width: 950px;
	}
}

.Menu-item--link {
	color: white;
	font-family: pridi-regular, $fontOptions;
	font-size: 16px;
	padding: 15px 0;
	transition: color .3s ease-in-out;

	@include media-query(lg) {
		font-size: 20px;
		padding: 20px 0;
	}

	&.active {
		border-bottom: 1px solid white;
	}

	&:visited {
		color: white;
		text-decoration: none;
	}

	&:hover {
		@include media-query(md) {
			color: $loblolly;
		}
	}
}