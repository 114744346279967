$alto: #DBDBDB;
$amber: #FFC200;
$apple: #439F46;
$boulder: #7D7D7D;
$cadetBlue: #B1BBC6;
$creamCan: #F4CF67;
$envy: #87AA87;
$loblolly: #C4CCD4;
$limeade: #64AA00;
$lynch: #627890;
$mercury: #E7E7E7;
$mineShaft: #222222;
$pomegranate: #F44336;
$silver: #BDBDBD;
$tonysPink: #E48380;
$wildSand: #F5F5F5;